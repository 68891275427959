import React from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import LandingPage from "./pages/landingPage";
export const Routes = () => {
  return (
    <div>
      <Switch>
        <Route path="/" component={LandingPage} />
      </Switch>
    </div>
  );
};
