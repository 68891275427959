/**
 * Configuração das rotas do APP para comunicação com a API
 */

export default {
  currentEnvironment: process.env.REACT_APP_ENVIROMENT,
  baseUrl: {
    dev: {
      urlApi: "https://app-planus-api-hml.azurewebsites.net/",
    },
    homolog: {
      urlApi: "https://app-planus-api-hml.azurewebsites.net/",
      // urlApi: "http://api.planus.io:9010/",
    },
    prod: {
      urlApi: "https://apiv2.planus.io/",
    },
  },
};
