import React, { useEffect } from "react";
import separador from "../../../../assets/icons/separador.svg";
import flagIcon from "../../../../assets/icons/flag-icon.png";
import rightArrow from "../../../../assets/svg/right-arrow.svg";
import leftArrow from "../../../../assets/svg/left-arrow.svg";
import profileImg from "../../../../assets/images/profile-img.PNG";
import FernandaProfile from "../../../../assets/images/FernandaProfile.jpg";
import "../contador/style.css";
import "../../style.css";
import "./style.css";
import TelaEstrutura from "../../../../componets/telaEstrutura";

const Depoimentos = () => {
  var slideIndex = 1;
  useEffect(() => {
    showSlides(slideIndex);
  }, []);

  // Next/previous controls
  const plusSlides = (n) => {
    showSlides((slideIndex += n));
  };

  // Thumbnail image controls
  function currentSlide(n) {
    showSlides((slideIndex = n));
  }

  const showSlides = (n) => {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
  };

  var PessoasDepoimentos = [
    {
      nome1: "Fernanda Pinheiro",
      nome2: "Paulo",
      funcao1: "Head of Administrativo",
      funcao2: "Tester",
      depoimento1:
        "O Planus integrou a execução dos projetos ao planejamento estratégico financeiro, uma vez que disponibiliza relatórios simples, efetivos e transparentes. Também vale ressaltar que a possibilidade de analisar os apontamentos em diferentes situações, como: clientes, usuários, tarefas e projetos permitiu avaliar a eficiência e distribuição de atividades de todos os usuários de forma prática e ágil.",
      depoimento2:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate a, suscipit dolorum maxime quibusdam pariatur quos culpa blanditiis exercitationem deserunt cum fuga corporis eos iusto ipsam numquam minima fugit praesentium",
    },
    {
      nome1: "Paulo",
      nome2: "Paulo",
      funcao1: "Tester",
      funcao2: "Tester",
      depoimento1:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate a, suscipit dolorum maxime quibusdam pariatur quos culpa blanditiis exercitationem deserunt cum fuga corporis eos iusto ipsam numquam minima fugit praesentium",
      depoimento2:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate a, suscipit dolorum maxime quibusdam pariatur quos culpa blanditiis exercitationem deserunt cum fuga corporis eos iusto ipsam numquam minima fugit praesentium",
    },
  ];
  return (
    <TelaEstrutura telaID="depoimento" titulo="DEPOIMENTOS">
      <div className="depoimento-container">
        <div className="depoimento-slide-container">
          <div className="slideshow-container">
            {PessoasDepoimentos.map((item) => (
              <div className="mySlides fade">
                {/* <div className="numbertext">1 / 3</div> */}
                <Depoimento
                  nome1={item.nome1}
                  // nome2={item.nome2}
                  funcao1={item.funcao1}
                  // funcao2={item.funcao2}
                  depoimento1={item.depoimento1}
                  // depoimento2={item.depoimento2}
                />
              </div>
            ))}
          </div>
          <a
            className="prev"
            onClick={() => plusSlides(-1)}
            style={{ visibility: "hidden" }}
          >
            {" "}
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => plusSlides(1)}
            style={{ visibility: "hidden" }}
          >
            &#10095;
          </a>
        </div>
        <div style={{ marginBottom: "20px", visibility: "hidden" }}>
          {" "}
          <div style={{ textAlign: "center" }}>
            <span className="dot" onclick={() => currentSlide(1)}></span>
            <span className="dot" onclick={() => currentSlide(1)}></span>
            <span className="dot" onclick={() => currentSlide(1)}></span>
          </div>
        </div>
      </div>
    </TelaEstrutura>
  );
};

export default Depoimentos;

const Depoimento = ({
  nome1,
  nome2,
  funcao1,
  funcao2,
  depoimento1,
  depoimento2,
}) => {
  return (
    <div className="depoimento-balao-container">
      <BalaoFala nome={nome1} funcao={funcao1} depoimento={depoimento1} />
      {/* <BalaoFala nome={nome2} funcao={funcao2} depoimento={depoimento2} /> */}
    </div>
  );
};

const BalaoFala = ({ nome, funcao, depoimento }) => {
  return (
    <div style={{ flex: "1", padding: "10px" }}>
      <blockquote className="speech-bubble">
        <cite>{depoimento}</cite>
      </blockquote>
      <div className="balao-container">
        <div className="depoimento-img">
          <img src={FernandaProfile} />
        </div>
        <div className="balao-descricao">
          <div className="balao-nome">{nome}</div>
          <div className="balao-funcao">{funcao}</div>
        </div>
      </div>
    </div>
  );
};
