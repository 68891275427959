import React, { useEffect } from "react";
import separador from "../../../../assets/icons/separador.svg";
import flagIcon from "../../../../assets/icons/flag-icon.png";
import rightArrow from "../../../../assets/svg/right-arrow.svg";
import leftArrow from "../../../../assets/svg/left-arrow.svg";
import printcarrousselmobile1 from "../../../../assets/images/print-mobile-1.png";
import printcarrousselmobile2 from "../../../../assets/images/print-mobile-2.png";
import printcarrousselmobile3 from "../../../../assets/images/print-mobile-3.png";
import printcarrousselmobile4 from "../../../../assets/images/print-mobile-4.png";
import printcarrousselmobile5 from "../../../../assets/images/print-mobile-5.png";
import printcarrousselmobile6 from "../../../../assets/images/print-mobile-6.png";
import printcarroussel1 from "../../../../assets/images/print-carroussel-1.png";
import printcarroussel2 from "../../../../assets/images/print-carroussel-2.png";
import printcarroussel3 from "../../../../assets/images/print-carroussel-3.png";
import printcarroussel4 from "../../../../assets/images/print-carroussel-4.png";
import printcarroussel5 from "../../../../assets/images/print-carroussel-5.png";
import printcarroussel6 from "../../../../assets/images/print-carroussel-6.png";

import "../contador/style.css";
import "../../style.css";
import "./style.css";
import TelaEstrutura from "../../../../componets/telaEstrutura";
const Funcionalidades = () => {
  const galleryContainer = document.querySelector(".gallery-container");
  const galleryControlsContainer = document.querySelector(".gallery-controls");
  var galleryControls = ["previous", "next"];
  const galleryItems = document.querySelectorAll(".gallery-item");

  useEffect(() => {
    setInterval(function () {
      prevNext(true);
    }, 6000);
  }, []);

  const prevNext = (target) => {
    // const target = document.getElementsByClassName(
    //   `gallery-controls-${control}`
    // );
    const selectedItem = document.querySelectorAll(".gallery-item-selected");
    const previousSelectedItem = document.querySelectorAll(
      ".gallery-item-previous"
    );
    const nextSelectedItem = document.querySelectorAll(".gallery-item-next");
    const firstCarouselItem = document.querySelectorAll(".gallery-item-first");
    const lastCarouselItem = document.querySelectorAll(".gallery-item-last");

    setarEstadoAtual(
      target,
      selectedItem,
      previousSelectedItem,
      nextSelectedItem,
      firstCarouselItem,
      lastCarouselItem
    );
  };

  // Update the order state of the carousel with css classes
  const setarEstadoAtual = (target, selected, previous, next, first, last) => {
    selected.forEach((el) => {
      el.classList.remove("gallery-item-selected");
      if (target) {
        el.classList.add("gallery-item-next");
      } else {
        el.classList.add("gallery-item-previous");
      }
    });

    previous.forEach((el) => {
      el.classList.remove("gallery-item-previous");

      if (target) {
        el.classList.add("gallery-item-selected");
      } else {
        el.classList.add("gallery-item-first");
      }
    });

    next.forEach((el) => {
      el.classList.remove("gallery-item-next");

      if (target) {
        el.classList.add("gallery-item-last");
      } else {
        el.classList.add("gallery-item-selected");
      }
    });

    first.forEach((el) => {
      el.classList.remove("gallery-item-first");

      if (target) {
        el.classList.add("gallery-item-previous");
      } else {
        el.classList.add("gallery-item-last");
      }
    });

    last.forEach((el) => {
      el.classList.remove("gallery-item-last");

      if (target) {
        el.classList.add("gallery-item-first");
      } else {
        el.classList.add("gallery-item-next");
      }
    });
  };
  return (
    <TelaEstrutura telaID="funcoes" titulo="FUNCIONALIDADES">
      <div className="galeria-main-container">
        <div className="gallery" style={{ position: "relative" }}>
          <div className="gallery-container">
            <GaleriaImg
              printcarroussel1={printcarroussel1}
              printcarroussel2={printcarroussel2}
              printcarroussel3={printcarroussel3}
              printcarroussel4={printcarroussel4}
              printcarroussel5={printcarroussel5}
              printcarroussel6={printcarroussel6}
            />
            <GaleriaImg
              printcarroussel1={printcarrousselmobile1}
              printcarroussel2={printcarrousselmobile2}
              printcarroussel3={printcarrousselmobile3}
              printcarroussel4={printcarrousselmobile4}
              printcarroussel5={printcarrousselmobile5}
              printcarroussel6={printcarrousselmobile6}
              type={"mobile"}
            />
            <PontosNav />
            <BtnNav
              direcao={false}
              icone={leftArrow}
              prevNext={prevNext}
              style={{ display: "none" }}
            />
            <BtnNav
              direcao={true}
              rightBtn="0px"
              icone={rightArrow}
              prevNext={prevNext}
            />
          </div>
        </div>
        {/* <div className="funcao-subtitulo">PROJETOS</div> */}
      </div>
    </TelaEstrutura>
  );
};

export default Funcionalidades;

const GaleriaImg = ({
  printcarroussel1,
  printcarroussel2,
  printcarroussel3,
  printcarroussel4,
  printcarroussel5,
  printcarroussel6,
  type = "web",
}) => {
  return (
    <div className={`show-gallery ${type}`}>
      <img src={printcarroussel1} className="gallery-item gallery-item-first" />
      <img
        src={printcarroussel2}
        className="gallery-item gallery-item-previous"
      />
      <img
        src={printcarroussel3}
        className="gallery-item gallery-item-selected"
      />
      <img src={printcarroussel4} className="gallery-item gallery-item-next" />
      <img src={printcarroussel5} className="gallery-item gallery-item-last" />
      <img src={printcarroussel6} className="gallery-item gallery-item-last" />
    </div>
  );
};

const PontosNav = () => {
  return (
    <ul class="gallery-nav">
      <li class="gallery-nav-item gallery-item-selected"></li>
      <li class="gallery-nav-item gallery-item-next"></li>
      <li class="gallery-nav-item gallery-item-last"></li>
      <li class="gallery-nav-item gallery-item-first"></li>
      <li class="gallery-nav-item gallery-item-previous"></li>
    </ul>
  );
};

const BtnNav = ({ direcao, rightBtn, icone, prevNext }) => {
  return (
    <button
      style={{ position: "absolute", right: rightBtn }}
      className="gallery-controls-next gallery-btn"
      onClick={() => prevNext(direcao)}
    >
      <img className="flagIcon" src={icone}></img>
    </button>
  );
};
