import { HttpStatus } from "../../utils/constants/http-status";
import axios from "axios";
import environment from "./environment";

export default class HttpService {
  constructor(props) {
    // const token = localStorage.getItem("planustk");
    const service = axios.create({
      baseURL: environment.baseUrl[environment.currentEnvironment],
      headers: {
        "Content-Type": "application/json",
      },
    });

    // if (token) {
    //   service.defaults.headers.common.Authorization = `Bearer ${token}`;
    // }
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.props = props;
  }

  //   loadToken(newToken) {
  //     this.service.defaults.headers.common.Authorization = `Bearer ${newToken}`;
  //   }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    if (error.response) {
      //   switch (error.response.status) {
      //     case HttpStatus.UNAUTHORIZED:
      //       if (error.response.data.hasOwnProperty("code")) {
      //         if (error.response.data.code === "token_not_valid") {
      //           localStorage.removeItem("planustk");
      //           localStorage.removeItem("plusr");
      //           window.location.href = "/";
      //         }
      //       }
      //       const token = localStorage.getItem("planustk");
      //       if (token) {
      //         localStorage.removeItem("planustk");
      //         localStorage.removeItem("plusr");
      //       }
      //       // if (window.location.pathname !== '/') {
      //       // 	window.location.href = '/';
      //       // }
      //       return error;
      //       break;
      //     default:
      //       return error;
      //   }
      return error;
    }
    Promise.reject(error);
  };

  //   redirectTo = (path) => {
  //     this.props.history.push(path);
  //   };

  get(path) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.get(url);
  }

  getWithCustomHeader(path, options = {}) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.get(url, options);
  }

  put(path, payload) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.put(url, payload);
  }

  putFile(path, payload) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.put(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  patch(path, payload) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.patch(url, payload);
  }

  patchFile(path, payload) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.patch(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  post(path, payload) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;

    return this.service.post(url, payload);
  }

  postFile(path, payload) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.post(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(path) {
    const url =
      environment.baseUrl[environment.currentEnvironment].urlApi + path;
    return this.service.delete(url);
  }
}
