import React from "react";
import separador from "../../assets/icons/separador.svg";
import flagIcon from "../../assets/icons/flag-icon.png";
import "./style.css";

const TelaEstrutura = ({ children, titulo, telaID }) => {
  return (
    <section
      className="container-estrutura"
      id={telaID}
      style={{ paddingTop: "10vh" }}
    >
      <div className="container-body-estrutura">
        {titulo && (
          <div className="container-estrutura-titulo">
            <img className="flagIcon" src={flagIcon}></img>
            <h1 className="estrutura-title">{titulo}</h1>
          </div>
        )}
        <div
          style={titulo ? { height: "74vh" } : { height: "84vh" }}
          className="estrutura-limitador"
        >
          {children}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", height: "5vh" }}
        >
          <div className="separador-img">
            <img src={separador}></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TelaEstrutura;
