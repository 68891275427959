import React, { useEffect, useState } from "react";
import separador from "../../../../assets/icons/separador.svg";
import clockIcon from "../../../../assets/svg/clockIcon.svg";
import registroIcon from "../../../../assets/svg/registroIcon.svg";
import equipeIcon from "../../../../assets/svg/equipeIcon.svg";

import MetricaService from "../../../../services/providers/metricas.providers";
import "./style.css";
import "../../style.css";
import TelaEstrutura from "../../../../componets/telaEstrutura";
import CountUp from "react-countup";
const Contador = () => {
  // const [projeto, SetProjetos] = useState(0);
  const [dataMetrica, setDataMetrica] = useState([]);

  useEffect(() => {
    // _getMetricas();
  });

  const _getMetricas = () => {
    setDataMetrica([]);
    const metricaService = new MetricaService();
    metricaService.getMetricas(
      (response) => {
        if (response.data) {
          console.log(response.data);
          setDataMetrica(response.data);
        }
      },
      (error) => {
        setDataMetrica([]);
      }
    );
  };

  var metricas = {
    projetos_totais: 63,
    horas_totais: 39913.0,
    empresas_totais: 10,
  };

  return (
    <TelaEstrutura telaID="contador">
      <div className="contador-container">
        <div className="contador-limitador">
          {/* <div style={{ color: "white" }} className="contador-container-icones"> */}
          <div>
            <span>
              <img
                src={registroIcon}
                style={{ width: "7rem", marginLeft: "4%" }}
              ></img>
            </span>
            <span className="contador-numero">
              <CountUp end={metricas.projetos_totais} />
            </span>
            <span className="contador-numero-titulo">Projetos Registrados</span>
          </div>

          <div>
            <span>
              {" "}
              <img src={clockIcon} style={{ width: "8rem" }}></img>
            </span>
            <span className="contador-numero">
              <CountUp end={metricas.horas_totais} id="teste" />
            </span>
            <span className="contador-numero-titulo">Horas apontadas</span>
          </div>
          <div>
            <span>
              <img src={equipeIcon} style={{ width: "11rem" }}></img>
            </span>
            <span className="contador-numero">
              <CountUp end={metricas.empresas_totais} />
            </span>
            <span className="contador-numero-titulo">Equipes cadastradas</span>
          </div>
          {/* </div> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <a
            href="http://planus-homolog.ivoryit.com.br/cadastro"
            style={{ textDecoration: "none", color: "white" }}
            className="contador-btn"
          >
            Testar Gratuitamente
          </a>
        </div>
      </div>
    </TelaEstrutura>
  );
};

export default Contador;
