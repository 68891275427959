import React from "react";
import separador from "../../../../assets/icons/separador.svg";
import bolinhas from "../../../../assets/svg/spark.svg";
import Linhas from "../../../../assets/svg/linhas";
import Typewriter from "typewriter-effect";

import "../../style.css";
import "./style.css";
const Apresentacao = () => {
  return (
    <section className="container-tela" id="apresentacao">
      {/* <img style={{ position: "absolute" }} src={linhas}></img> */}
      <Linhas />
      <div className="banner-image">
        <div className="container-apresentacao">
          <img className="bolinhas-top" src={bolinhas}></img>
          <div className="banner-text-container">
            <h1 className="banner-text">
              <Typewriter
                options={{
                  strings: [
                    "Tenha todo o controle de tempo da sua empresa em um único lugar.",
                    "Saiba qual é o custo real de cada tarefa executada por sua equipe.",
                    "Utilize os dados ao seu favor para planejar novos projetos.",
                    "Faça medições financeiras com seus clientes de maneira transparente.",
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 10,
                  delay: 50,
                }}
              />
            </h1>
            <img className="linha-apresentacao" src={separador}></img>
            <button className="banner-btn">
              <a
                href="https://app.planus.io/cadastro"
                style={{ textDecoration: "none", color: "white" }}
              >
                Testar Gratuitamente
              </a>
            </button>
          </div>
          <img className="bolinhas-bottom" src={bolinhas}></img>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "0.5vh",
          background: "linear-gradient(to right ,#23D9B7,#D7F205 )",
          marginBotton: "0",
        }}
      ></div>
    </section>
  );
};

export default Apresentacao;
