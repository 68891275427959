import React, { useState } from "react";
import "./style.css";
import logoNav from "../../assets/images/logo.png";
import hamburgerSVG from "../../assets/svg/hamburger.svg";
const NavBar = ({ scrolarIndice }) => {
  const [opcaoMobile, setOpcaoMobile] = useState(false);
  const campos = [
    { label: "Planejamento Ágil", indice: "planejamento" },
    { label: "Funcionalidades", indice: "funcoes" },
    { label: "Depoimentos", indice: "depoimento" },
    { label: "Planos", indice: "assinatura" },
    { label: "Contato", indice: "contato" },
  ];

  return (
    <header className="container-navbar">
      {/* MOBILE */}
      <div
        id="navbar-mobile"
        style={{
          display: opcaoMobile ? "flex" : "none",
        }}
      >
        <div className="cabecalho-nav-mobile">
          <ul
            style={{
              flex: "0.8",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {campos.map((item) => (
              <li
                onClick={() => {
                  scrolarIndice(item.indice);
                  setOpcaoMobile(false);
                }}
              >
                {item.label}
              </li>
            ))}
          </ul>
          <ul
            style={{
              flex: "0.3",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              marginTop: "7vh",
            }}
            className="btn-navbar-mobile"
          >
            <li>
              {" "}
              <a
                href="https://app.planus.io/cadastro"
                style={{ color: "#23D9B7", background: "#fff" }}
              >
                Criar conta
              </a>
            </li>
            <li>
              <a style={{ color: "#fff" }} href="https://app.planus.io/login">
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* FIM MOBILE */}
      <nav className="conteudo-navbar">
        <img
          onClick={() => scrolarIndice("apresentacao")}
          className="logo-planus"
          src={logoNav}
          a="/"
        ></img>
        <div id="cabecalho-navegadores">
          <ul>
            {campos.map((item) => (
              <li onClick={() => scrolarIndice(item.indice)}>{item.label}</li>
            ))}
          </ul>
          <div className="btn-navbar">
            <a
              href="https://app.planus.io/cadastro"
              style={{
                color: "#23D9B7",
                background: "#fff",
                marginRight: "20px",
              }}
            >
              Criar conta
            </a>
            <a
              style={{ color: "#fff", marginRight: "20px" }}
              href="https://app.planus.io/login"
            >
              Login
            </a>
          </div>
        </div>
        <div id="hamburger" onClick={() => setOpcaoMobile(!opcaoMobile)}>
          <img
            src={hamburgerSVG}
            style={{ width: "50px", padding: "1px 4px" }}
          />
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
