import HttpService from "../config/http";

export default class ContatoService {
  constructor(props) {
    this.api = new HttpService(props);
  }

  postContato = (params, success, errorCallback) => {
    this.api
      .post("api/v1/pessoas-planus/feedbacksite/", params)
      .then((result) => {
        if (result.status === 201) {
          success(result);
        } else {
          if (typeof result.response.data === typeof {}) {
            const objError = result.response.data;
            for (var key in objError) {
              throw `${objError[key]}`;
            }
          } else {
            throw "Erro ao tentar salvar o cliente!";
          }
        }
      })
      .catch((error) => errorCallback(error));
  };
}
