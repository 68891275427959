import React from "react";
import { Routes } from "../../routes";
// import NavBar from "../../componets/navbar";

const MainPage = () => {
  return (
    <div>
      {/* <NavBar /> */}
      <Routes />
    </div>
  );
};

export default MainPage;
