import React from "react";
import "../../style.css";
import "./style.css";
import gerenciamentoIcon from "../../../../assets/svg/gerenciamentoIcon.svg";
import apontamentoIcon from "../../../../assets/svg/apontamentoIcon.svg";
import controleIcon from "../../../../assets/svg/controleIcon.svg";
import gestaoIcon from "../../../../assets/svg/gestaoIcon.svg";
import relatorioIcon from "../../../../assets/svg/relatorioIcon.svg";
import horasIcon from "../../../../assets/svg/horasIcon.svg";
import flagIcon from "../../../../assets/icons/flag-icon.png";
import TelaEstrutura from "../../../../componets/telaEstrutura";

const Planejamento = () => {
  return (
    <TelaEstrutura telaID="planejamento" titulo="PLANEJAMENTO ÁGIL">
      <div className="apresentacao-limitador-row">
        {campoTopicos &&
          campoTopicos.map((item) => (
            <Topicos
              titulo={item.titulo}
              icone={item.icone}
              texto={item.texto}
            />
          ))}
      </div>
    </TelaEstrutura>
  );
};
export default Planejamento;

const Topicos = ({ titulo, icone, texto }) => {
  return (
    <div>
      <span>
        <img className="apresentacao-img" src={icone} />
      </span>
      <span className="apresentacao-title-card">{titulo}</span>
      <span className="apresentacao-text-card">{texto}</span>
      <div className="container-apresentacao-body-box-text"></div>
    </div>
  );
};

const campoTopicos = [
  {
    titulo: "GERENCIAMENTO DE PROJETOS",
    icone: gerenciamentoIcon,
    texto:
      "Tenha uma visão geral dos projetos, mantenha sua equipe focada nas atividades e gerencie prazos.",
  },
  {
    titulo: "APONTAMENTO DE HORAS",
    icone: apontamentoIcon,
    texto:
      "Registre suas horas ou dispare o cronômetro. Tenha de forma ágil uma Timeline eficiente.",
  },
  {
    titulo: "CONTROLE DE ATIVIDADES",
    icone: controleIcon,
    texto:
      "Atribua tarefas para o seu time, gerando mais controle para sua equipe e gestores.",
  },
  {
    titulo: "GESTÃO DE PESSOAS",
    icone: gestaoIcon,
    texto: "Cadastre sua equipe, adicione-as em projetos e atribua atividades.",
  },
  {
    titulo: "RELÁTORIOS",
    icone: relatorioIcon,
    texto:
      "Veja em tempo real as informações da sua empresa, com relatórios personalizados de acordo com a sua necessidade.",
  },
  ,
  {
    titulo: "HORAS COBRÁVEIS",
    icone: horasIcon,
    texto:
      "   Utilize informações no Planus para calcular os valores do seu projeto, exporte relatórios para seus clientes.",
  },
];
