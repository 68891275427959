import React, { useState } from "react";
import ContatoService from "../../../../services/providers/contato.providers";
import flagIcon from "../../../../assets/icons/flag-icon.png";
import logoNav from "../../../../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../../style.css";
import "./style.css";

const Contatos = () => {
  const [contatoEnviado, setContatoEnviado] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = yup.object().shape({
    nome: yup.string().required("Campo obrigatório."),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório."),
    telefone: yup.string().required("Campo obrigatório."),
    mensagem: yup.string().required("Campo obrigatório."),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const _postContato = (params, e) => {
    const contatoService = new ContatoService();
    setLoading(true);
    contatoService.postContato(
      params,
      (response) => {
        if (response) {
          // toast.success("Registro de hora salvo com sucesso!");
          // props.handlePostPatchHora(props.saveAndContinue);
          setLoading(false);
          setContatoEnviado(true);
          resetFields(e);
        }
        // props.handleLoading(false);
      },
      (error) => {
        setLoading(false);
        setContatoEnviado(false);
        // toast.error(error);
        // props.handleLoading(false);
      }
    );
  };
  const resetFields = (e) => {
    e.stopPropagation();
    e.target[0].value = ""; // nome
    e.target[1].value = ""; //email
    e.target[2].value = ""; //telefone
    e.target[3].value = ""; //mensagem
  };
  return (
    <section className="contato-tela" id="contato">
      <div className="container-contato">
        <div className="container-contato-titulo">
          <img className="flagIcon" src={flagIcon}></img>
          <h1 className="contato-titulo">CONTATO</h1>
        </div>
        <div className="contato-limitador-row">
          {contatoEnviado && (
            <div className="contato-avisso">
              Obrigado por entrar em contato, recebemos seu E-mail em breve
              retornaremos!
            </div>
          )}
          <form
            className="contato-container-form"
            onSubmit={handleSubmit(_postContato)}
          >
            <div style={{ flex: "1", padding: "1rem 2rem" }}>
              <div className="contato-input-container">
                <label className="contato-label">Nome:</label>
                <input
                  name="nome"
                  ref={register}
                  className="contato-input"
                  autoComplete={"off"}
                  disabled={loading}
                ></input>
                <div className="contato-error">{errors.nome?.message}</div>
              </div>
              <div className="contato-input-container">
                <label className="contato-label">Email:</label>
                <input
                  name="email"
                  ref={register}
                  className="contato-input"
                  autoComplete={"off"}
                  disabled={loading}
                ></input>
                <div className="contato-error">{errors.email?.message}</div>
              </div>
              <div className="contato-input-container">
                <label className="contato-label">Telefone:</label>
                <input
                  name="telefone"
                  ref={register}
                  className="contato-input"
                  autoComplete={"off"}
                  disabled={loading}
                ></input>
                <div className="contato-error">{errors.telefone?.message}</div>
              </div>
            </div>
            <div style={{ flex: "1", padding: "1rem 2rem" }}>
              <label className="contato-label">Mensagem:</label>
              <textarea
                name="mensagem"
                ref={register}
                className="contato-textbox contato-input"
                autocomplete="off"
                type="text"
                disabled={loading}
              ></textarea>
              <div className="contato-error">{errors.mensagem?.message}</div>
              <button
                className="contato-btn"
                disabled={loading}
                onClick={() => setContatoEnviado(false)}
              >
                {loading === false ? (
                  "Enviar"
                ) : (
                  <div className="loader-login"></div>
                )}{" "}
              </button>
            </div>
          </form>
        </div>
        <div className="contato-borda"></div>
        <Footer />
      </div>
    </section>
  );
};

export default Contatos;

const Footer = () => {
  return (
    <footer id="footer">
      <section id="footer-main-container">
        <div>
          <img className="logo-planus-footer" src={logoNav} a="/" />
        </div>
        <div className="footer-text-container">
          Copyright 2021 | Planus | Todos os direitos reservados
        </div>
      </section>
    </footer>
  );
};
