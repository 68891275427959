import React from "react";
import "./style.css";
const Linhas = () => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 4990.59 2276.44"
      preserveAspectRatio="xMidYMid meet"
    >
      {/* <defs>
        <clipPath id="clip-path" transform="translate(1746.33 750.57)">
          <rect class="cls-1" x="-554" width="1920" height="772" />
        </clipPath>
        <clipPath id="clip-path-2" transform="translate(1746.33 750.57)">
          <rect
            id="SVGID"
            class="cls-1"
            x="2"
            width="1791"
            height="768"
            transform="translate(-1.69 3.98) rotate(-0.25)"
          />
        </clipPath>
      </defs> */}
      <g id="Characters" class="cls-2">
        <g id="Grupo_50" data-name="Grupo 50">
          <g class="cls-3">
            <g id="Grupo_49" data-name="Grupo 49">
              <path
                id="Caminho_86"
                data-name="Caminho 86"
                class="cls-4"
                d="M1370.33-595.45s210.78,371.62-92.73,838.64,128.87,812.16,710,814.92"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_87"
                data-name="Caminho 87"
                class="cls-4"
                d="M1511-482.61s215.85,246.22,24.17,533.47-476.8,606.86,306.06,895.36"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_88"
                data-name="Caminho 88"
                class="cls-4"
                d="M1616.51,1381.78c-8-6.21-805.29-211.19-583.16-646.61S1086,103.37,609.51-135.17"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_89"
                data-name="Caminho 89"
                class="cls-4"
                d="M3111.16-750.56S3127.15-345,2619.89.85,2347,791.5,2884.87,961.41"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_90"
                data-name="Caminho 90"
                class="cls-4"
                d="M3234.47-546.63S3315.76-256,2999.11-44.7,2263.5,381,2850.44,874.16"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_91"
                data-name="Caminho 91"
                class="cls-4"
                d="M2601.64,939.23c-4.47-8.09-782.08-390-365.6-730s354.16-571,27.31-929.56"
                transform="translate(1746.33 750.57)"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Characters-2" data-name="Characters">
        <g id="Grupo_50-2" data-name="Grupo 50">
          <g class="cls-5">
            <g id="Grupo_49-2" data-name="Grupo 49">
              <path
                id="Caminho_86-2"
                data-name="Caminho 86"
                class="cls-6"
                d="M2.3,1364.35S-196,995.52,85.1,529.67-38.69-277.74-580.79-278.08"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_87-2"
                data-name="Caminho 87"
                class="cls-6"
                d="M-129.43,1252.67s-202.43-244.05-24.89-530.6S287.76,116.39-443.77-167.38"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_88-2"
                data-name="Caminho 88"
                class="cls-6"
                d="M-236.06-601.61c7.54,6.15,752.11,206.77,546.83,640.85S264.42,668,710,903.3"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_89-2"
                data-name="Caminho 89"
                class="cls-6"
                d="M-1620.87,1525.85s-16.7-403.34,454.95-749.54,251-787.67-251.42-954.48"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_90-2"
                data-name="Caminho 90"
                class="cls-6"
                d="M-1736.79,1323.49s-77.11-288.76,217.33-500.3,684.3-426.56,134.62-914.71"
                transform="translate(1746.33 750.57)"
              />
              <path
                id="Caminho_91-2"
                data-name="Caminho 91"
                class="cls-6"
                d="M-1153-157.28c4.2,8,731.25,384.74,344.25,724.71s-327.84,569.52-21.37,924.85"
                transform="translate(1746.33 750.57)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Linhas;
