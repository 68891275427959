import React from "react";
import "../../style.css";
import "./style.css";
import separador from "../../../../assets/icons/separador.svg";
import flagIcon from "../../../../assets/icons/flag-icon.png";
import gratuitoIcon from "../../../../assets/svg/gratuitoIcon.svg";
import completoIcon from "../../../../assets/svg/completoIcon.svg";
import TelaEstrutura from "../../../../componets/telaEstrutura";

var PlanosItensGratuito = [
  { itemGratuito: "4 projetos" },
  { itemGratuito: "5 usuários" },
  { itemGratuito: "1 cliente" },
  { itemGratuito: "Gerenciamento de equipes" },
  { itemGratuito: "Dashboard de apontamentos" },
  { itemGratuito: "Histórico de apontamentos" },
  { itemGratuito: "Relatório diário" },
  { itemGratuito: "Relatório financeiro" },
];
var PlanosItensPagos = [
  { itemPago: "Projetos ilimitados" },
  { itemPago: "Usuários ilimitados" },
  { itemPago: "Clientes ilimitados" },
  { itemPago: "100 MB de upload de arquivos por usuário mensal" },
  { itemPago: "Gerenciamento de equipes" },
  { itemPago: "Dashboard de apontamentos" },
  { itemPago: "Histórico de apontamentos" },
  { itemPago: "Todos os relatórios" },
  { itemPago: "Calendário semanal completo" },
];

const assinatura = () => {
  return (
    <TelaEstrutura telaID="assinatura" titulo="PLANOS">
      <div className="planos-container">
        <div className="planos-limitador-row">
          <Card
            titulo="COMPLETO"
            icone={gratuitoIcon}
            itens={
              PlanosItensPagos &&
              PlanosItensPagos.map((item) => (
                <li className="planos-text">{item.itemPago}</li>
              ))
            }
          />
          <Card
            titulo="GRATUITO"
            icone={completoIcon}
            itens={
              PlanosItensGratuito &&
              PlanosItensGratuito.map((item) => (
                <li className="planos-text">{item.itemGratuito}</li>
              ))
            }
            cor="#31b3a2"
          />
        </div>
      </div>
    </TelaEstrutura>
  );
};

export default assinatura;

const Card = ({ titulo, icone, cor, itens }) => {
  return (
    <div className="planos-card">
      <span>
        <img
          className="planos-icon"
          className="planos-icon-mobile"
          src={icone}
        ></img>
        <h1 className="planos-gratuito-title" style={{ color: cor }}>
          {titulo}
        </h1>
      </span>
      <span>
        <img
          className="planos-icon"
          style={{ width: "100px" }}
          src={icone}
        ></img>
      </span>
      <span style={{ width: "125vh" }}>{itens}</span>
      <div>
        <button className="planos-btn" style={{ display: "none" }}>
          Começar
        </button>
      </div>
    </div>
  );
};
