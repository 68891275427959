import React from "react";
import Apresentacao from "./telas/apresentacao";
import Planejamento from "./telas/planejamento";
import Contador from "./telas/contador";
import Funcionalidades from "./telas/funcionalidades";
import Depoimentos from "./telas/depoimentos";
import Assinatura from "./telas/assinatura";
import Contatos from "./telas/contatos";
import NavBar from "../../componets/navbar";

const LandingPage = () => {
  let scrollIndice = false;

  const scrolarIndice = (elementoID) => {
    scrollIndice = true;
    const elementScroll = document.getElementById("landing-scroll");
    const campoScroll = document.getElementById(elementoID);

    elementScroll.scroll({
      top: campoScroll.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      scrollIndice = false;
    }, 1000);
  };

  return (
    <>
      <NavBar scrolarIndice={scrolarIndice} />
      <main
        className="landing-container"
        id="landing-scroll"
      >
        <Apresentacao />
        <Planejamento />
        <Contador />
        <Funcionalidades />
        <Depoimentos />
        <Assinatura />
        <Contatos />
      </main>
    </>
  );
};

export default LandingPage;
