import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import MainPage from "./pages/mainPage";
import { BrowserRouter } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import TagManager from 'react-gtm-module'
import "./global.css";


const tagManagerArgs = {
  gtmId: process.env.REACT_APP_ENVIROMENT === "prod" ? "GTM-M8F5QBZ" : ""
}
TagManager.initialize(tagManagerArgs)


const MetaPixelId =
  process.env.REACT_APP_ENVIROMENT === "prod" ? "940810897203242" : "";

const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });

  useEffect(() => {
    ReactPixel.init(MetaPixelId);
    ReactPixel.pageView();
  }, []);

  return (
    <React.StrictMode>
      {" "}
      <BrowserRouter>
        {" "}
        <MainPage />{" "}
      </BrowserRouter>{" "}
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
